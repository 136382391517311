// Breakpoints
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1440px;

// Colors
$color-white: #FFF;
$color-dark: #090C02;
$color-dark-green: #002626;
$color-green: #0E4749;
$color-red: #D00000;
$color-dark-pink: #B23A48;
$color-light-pink: #D0ADA7;
$color-cream: #FEFAE0;

// Typography 
$font-root-path: '/assets/fonts/';
$font-family-fallback: sans-serif;
$font-family-primary: 'Playfair Display', $font-family-fallback;
$font-family-secondary: 'Poppins', $font-family-fallback;
// $font-family-secondary: 'Josefin Sans', $font-family-fallback;
// $font-family-secondary: 'Raleway', $font-family-fallback;