// Fonts
@at-root {
	@font-face {
	  font-family: BlueHighway-Bold;
	  src: url($font-root-path + 'bluehigb.eot'),
	       url($font-root-path + 'bluehigb.woff2') format('woff2'),
	       url($font-root-path + 'bluehigb.woff') format('woff');
	}
}

// Setting element fonts
body {
	font-family: $font-family-secondary;
}

h1 {
	@include heading-1;
}

h2 {
	@include heading-2;
}