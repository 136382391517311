$ns: '.projects-v3';

#{$ns} {
  &__project-container {
    &.video-active {
      @for $i from 1 through 6 {
        &.video-active--#{$i} {
          #{$ns}__info--#{$i} {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  &__info {
    position: absolute;
    left: 50%;
    bottom: 4%;
    transform: translate(-50%,-50%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    text-align: center;
    width: 100%;
  }

  &__title {
    font-family: $font-family-primary;
    color: $color-dark-pink;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  &__disclaimer {
    font-family: $font-family-secondary;
    font-size: 8px;
  }

  &__visit {
    font-family: $font-family-secondary;
    font-size: 16px;
    color: $color-dark-pink;

    @include link-hover(2px);
  }

  &__scroll-icon {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%,-50%);

    display: block;
    height: 3em;
    width: 1.5em;
    border: 1px solid $color-dark;
    border-radius: 1em;
    
    animation-name: scroll-notification;
    animation-delay: 5s;
    animation-fill-mode: forwards;
  
    &--dot {
      display: block;
      position: absolute;
      left: 50%;
      background: $color-dark;
      height: .25em;
      width: .25em;
      top: .8em;
      margin-left: -.125em;
      border-radius: 50%;
      transform-origin: top center;
      backface-visibility: hidden;
      animation: scroll 2s ease-out infinite;
    }
  }
}