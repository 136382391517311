$ns: '.about';

#{$ns} {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  
  @include at('tablet') {
    justify-content: center;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
  }

  &__heading {
    color: $color-red;
    margin-bottom: 32px;
    text-transform: uppercase;
  }

  &__info {
    width: 50%;
    margin-bottom: 90px;

    @include at('tablet') {
      margin-bottom: 0;
    }
  
    &--paragraph {
      font-family: $font-family-secondary;
      margin-bottom: 24px;
    }

    &--email {
      color: $color-red;

      @include link-hover(3px);
    }
  }
}