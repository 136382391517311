$ns: '.navSocials';

#{$ns} {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  
  &__social-icon {
    width: 16px;
    padding-bottom: 18px;
  }
  
  @include at('tablet') {
    bottom: 128px;
    top: unset;

    &:after {
      position: absolute;
      content: '';
      top: -172px;
      left: 50%;
      height: 140px;
      width: 1px;
      background-color: $color-dark;
    }
  }
}