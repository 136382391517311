@mixin heading-1 {
  color: $color-dark;
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 6vw;
  line-height: 6vw;
}

@mixin heading-2 {
  color: $color-dark;
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 7px;

  @include at('tablet') {
    font-size: 16px;
    line-height: 16px;
  }
}