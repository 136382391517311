
@mixin link-hover($space) {
  transition: letter-spacing 0.5s ease;

  &:hover {
    letter-spacing: $space;
  }
}

@keyframes scroll {
  0%   { transform: translateY(0); opacity: 1; }
  25%  { opacity: 1; }
  75%  { transform: translateY(-.50em); opacity: 0; }
  100% { transform: translateY(0); opacity: 0; }
}

@keyframes scroll-notification {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}