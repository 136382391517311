$ns: '.navLink';

#{$ns} {
  position: fixed;
  
  &__title {
    text-transform: uppercase;
    
    @include link-hover(10px);
  }

  // Themes
  &--about {
    bottom: 24px;
  }

  &--lab {
    top: 24px;
    right: 24px;
    
    @include at('tablet') {    
      right: 279px;

      &:after {
        position: absolute;
        content: '';
        right: -255px;
        top: 50%;
        width: 240px;
        height: 1px;
        background-color: $color-dark;
      }
    }
  }

  &--projects {
    writing-mode: vertical-lr;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
}