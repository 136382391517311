// Resets
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

ul {
	list-style-type: none;
}

a {
	text-decoration: none;
}

body {
	box-sizing: border-box;
	overscroll-behavior: none;
}