$ns: '.logo';

#{$ns} {
  position: fixed;
  top: 20px;
  left: 20px;

  &__image {
    width: 70px;
  }
}