$ns: '.home';

#{$ns} {
  &__webgl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &__container {
    width: 100vw;
    height: 100vh;

    @include at('tablet') {
      height: 200vh;
    }
  }

  &__scrollPercentage {
    position: fixed;
    bottom: 10%;
    left: 40%;
  }

  &__heading-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__heading {
    line-height: 11vw;

    &--name {
      color: $color-red;
    }

    &--sub {
      font-size: 2vw;
      line-height: 2vw;
      letter-spacing: 1vw;
      color: $color-light-pink;
    
      @include at('tablet') {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 8px;
      }
    }
  }
}